import './Sidenav.scss';
import MainRouter from './MainRouter';

function Sidenav() {
  return (
    <>
      <MainRouter />
    </>
  );
}

export default Sidenav;
